"use strict";

var mya = mya || {};

mya.Cookiebar = {

    Selector : {
        COOKIEBAR : 'myaCookieBar',
        COOKIEBUTTON : 'myaCookieBarButton'
    },


    Key : {
        COOKIECONSENT : 'myaCookieConsent'
    },


    ClassName : {
        SHOW : 'mya-show'
    },


    Cookiebar : class {

        constructor() {
            this._cookiebar = this._getCookiebar();
            this._cookiebutton = this._getCookieButton();
            this._storage = this._getStorage();

            if (!this.hasConsent()) {
                this.show();
            }
        }

        _getCookiebar() {
            if (!this._cookiebar) {
                let cookiebar = document.getElementById(mya.Cookiebar.Selector.COOKIEBAR);
                if (cookiebar) {
                    this._cookiebar = cookiebar;
                }
                else console.error("cookiebar '"+mya.Cookiebar.Selector.COOKIEBAR+"' not found");
            }
            return this._cookiebar;
        }

        _getCookieButton() {
            if (!this._cookiebutton) {
                let button = document.getElementById(mya.Cookiebar.Selector.COOKIEBUTTON);
                if (button) {
                    this._cookiebutton = button;
                }
                else console.error("cookiebutton '"+mya.Cookiebar.Selector.COOKIEBUTTON+"' not found");
            }
            return this._cookiebutton;
        }

        _getStorage() {
            if (!this._storage) {
                if (this._isStorageAvailable('localStorage')) {
                    this._storage = localStorage;
                }
                else if (this._isStorageAvailable('sessionStorage')) {
                    this._storage = sessionStorage;
                }
                else {
                    this._storage = false
                }
            }
            return this._storage;
        }

        _isStorageAvailable(type) {
            let storage;
            try {
                storage = window[type];
                let x = '__storage_test__';
                storage.setItem(x, x);
                storage.removeItem(x);
                return true;
            }
            catch(e) {
                return false;
            }
        }

        hasConsent() {
            if (this._storage && this._storage.getItem(mya.Cookiebar.Key.COOKIECONSENT) === 'true') {
                return true;
            }
            else {
                return false;
            }
        }

        saveConsent(consent) {
            if (this._storage) {
                this._storage.setItem(mya.Cookiebar.Key.COOKIECONSENT, consent);
            }
        }

        clearConsent() {
            if (this._storage) {
                this._storage.removeItem(mya.Cookiebar.Key.COOKIECONSENT);
            }
        }

        acceptCookies() {
            this.saveConsent('true');
            this.hide();
        }

        show() {
            this._cookiebar.classList.add(mya.Cookiebar.ClassName.SHOW);
        }

        hide() {
            this._cookiebar.classList.remove(mya.Cookiebar.ClassName.SHOW);
        }

    },


    init : function() {

        mya.DEBUG && console.debug("mya.Cookiebar.init");

        let cookiebar = new mya.Cookiebar.Cookiebar();
        let cookiebutton = cookiebar._getCookieButton();

        cookiebutton && cookiebutton.addEventListener('click', function handleCookiebarAcceptCookies(event){
            event.preventDefault();
            cookiebar.acceptCookies();
        });
    }

};
