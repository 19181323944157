"use strict";

var mya = mya || {};

mya.NavMenu = {

    Selector : {
        TOGGLE : 'myaNavbarToggle',
        MENU : 'myaNavbar'
    },


    ClassName : {
        SHOW : 'mya-show',
        MENU_OPEN : 'mya-svg__times',
        MENU_CLOSED : 'mya-svg__bars'
    },


    Event : {
        CLICK : 'click'
    },


    SpriteSymbol : {
        MENU_OPEN : '/img/sprite-icons.svg#symbol-times',
        MENU_CLOSED : '/img/sprite-icons.svg#symbol-bars'
    },


    NavMenu : class {

        constructor() {
            this._toggle = this._getToggle(); // this is the toggle element
            this._menu = this._getMenu(); // this is the target element
        }

        _getToggle() {
            if (!this._toggle) {
                let toggle = document.getElementById(mya.NavMenu.Selector.TOGGLE);
                if (toggle) {
                    this._toggle = toggle;
                }
                else console.error("navmenutoggle '"+mya.NavMenu.Selector.TOGGLE+"' not found");
            }
            return this._toggle;
        }

        _getMenu() {
            if (!this._menu) {
                let menu = document.getElementById(mya.NavMenu.Selector.MENU);
                if (menu) {
                    this._menu = menu;
                }
                else console.error("navmenu '"+mya.NavMenu.Selector.MENU+"' not found");
            }
            return this._menu;
        }

        toggle() {
            if ( this._menu.classList.contains(mya.NavMenu.ClassName.SHOW) ) {
                this.hide();
            }
            else {
                this.show();
            }
        }

        show() {
            this._toggle.setAttribute('aria-expanded', true);
            this._menu.classList.add(mya.NavMenu.ClassName.SHOW);
            let svg = this._toggle.querySelector('svg');
            svg.classList.remove(mya.NavMenu.ClassName.MENU_CLOSED);
            svg.classList.add(mya.NavMenu.ClassName.MENU_OPEN);
            svg.querySelector('use').setAttribute('href', mya.NavMenu.SpriteSymbol.MENU_OPEN);
        }

        hide() {
            this._toggle.setAttribute('aria-expanded', false);
            this._menu.classList.remove(mya.NavMenu.ClassName.SHOW);
            let svg = this._toggle.querySelector('svg');
            svg.classList.remove(mya.NavMenu.ClassName.MENU_OPEN);
            svg.classList.add(mya.NavMenu.ClassName.MENU_CLOSED);
            svg.querySelector('use').setAttribute('href', mya.NavMenu.SpriteSymbol.MENU_CLOSED);
        }

    },


    init : function() {

        mya.DEBUG && console.debug("mya.NavMenu.init");

        let navmenu = new mya.NavMenu.NavMenu();
        let navmenutoggle = navmenu._getToggle();

        navmenutoggle && navmenutoggle.addEventListener(mya.NavMenu.Event.CLICK, function handleNavMenuToggle(event) {
            event.preventDefault();
            navmenu.toggle();
        });
    }

};
