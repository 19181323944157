"use strict";

var mya = mya || {};

mya.Search = {

    Selector : {
        TOGGLE : 'myaSearchOverlayToggle',
        OVERLAY : 'myaSearchOverlay',
        INPUT : 'myaSearchQuickInput',
        SUBMIT : 'myaSearchQuickSubmit',
        CLOSE : 'myaSearchOverlayClose',
        INPUT_ON_RESULTS : 'myaSearchInput'
    },


    // Focusable elements included in the search overlay (sorted by focusable order)
    Focusable : [ 'myaSearchQuickInput', 'myaSearchQuickSubmit', 'myaSearchOverlayClose' ],


    ClassName : {
        SHOW : 'mya-search-overlay_show',
        NOSCROLL : 'mya-no-scroll'
    },


    Event : {
        KEYUP : 'keyup',
        KEYDOWN : 'keydown',
        CLICK : 'click'
    },


    Key : {
        ESC : 27,
        TAB : 9
    },


    Delay : 200,


    // A singleton reference to an instance of Search class.
    // This is used to get a reference to this same instance when outside of the class and without the need to pass parameters.
    _search : null,

    getInstance : function() {
        if (mya.Search._search == null) {
            mya.Search._search = new mya.Search.Search();
        }
        return mya.Search._search;
    },


    Search : class {

        constructor() {
            this._toggle = this._getToggle(); // toggle element
            this._overlay = this._getOverlay(); // target element
            this._input = this._getInput(); // search input element
            this._submit = this._getSubmit(); // search submit element
            this._close = this._getClose(); // overlay close element

            this._placeholder = this._input?.getAttribute('placeholder'); // save the placeholder as it was originally defined in HTML
        }

        _getToggle() {
            if (!this._toggle) {
                let toggle = document.getElementById(mya.Search.Selector.TOGGLE);
                if (toggle) {
                    this._toggle = toggle;
                }
                else console.error("searchtoggle '"+mya.Search.Selector.TOGGLE+"' not found");
            }
            return this._toggle;
        }

        _getOverlay() {
            if (!this._overlay) {
                let overlay = document.getElementById(mya.Search.Selector.OVERLAY);
                if (overlay) {
                    this._overlay = overlay;
                }
                else console.error("searchoverlay '"+mya.Search.Selector.OVERLAY+"' not found");
            }
            return this._overlay;
        }

        _getInput() {
            if (!this._input) {
                let input = document.getElementById(mya.Search.Selector.INPUT);
                if (input) {
                    this._input = input;
                }
                else console.error("searchinput '"+mya.Search.Selector.INPUT+"' not found");
            }
            return this._input;
        }

        _getSubmit() {
            if (!this._submit) {
                let submit = document.getElementById(mya.Search.Selector.SUBMIT);
                if (submit) {
                    this._submit = submit;
                }
                else console.error("searchsubmit '"+mya.Search.Selector.SUBMIT+"' not found");
            }
            return this._submit;
        }

        _getClose() {
            if (!this._close) {
                let close = document.getElementById(mya.Search.Selector.CLOSE);
                if (close) {
                    this._close = close;
                }
                else console.error("searchclose '"+mya.Search.Selector.CLOSE+"' not found");
            }
            return this._close;
        }

        toggle() {
            if ( this._overlay.classList.contains(mya.Search.ClassName.SHOW) ) {
                this.hide();
            }
            else {
                this.show();
            }
        }

        show() {
            // Avoid scrolling of background body when overlay is shown
            document.body.classList.add(mya.Search.ClassName.NOSCROLL);

            this._input.setAttribute('placeholder', '');
            this._overlay.classList.add(mya.Search.ClassName.SHOW);
            this._toggle.setAttribute('aria-expanded', true);

            setTimeout(() => {
                this._input.focus();
            }, mya.Search.Delay);

            mya.Search.registerEventListeners();
        }

        hide() {
            this._toggle.setAttribute('aria-expanded', false);
            this._overlay.classList.remove(mya.Search.ClassName.SHOW);
            this._input.setAttribute('placeholder', this._placeholder);

            // Restore scrolling of background body
            document.body.classList.remove(mya.Search.ClassName.NOSCROLL);

            setTimeout(() => {
                this._toggle.focus();
            }, mya.Search.Delay);

            mya.Search.unregisterEventListeners();
        }

    }, // Search class


    handleEvent : function(event) {

        if (!event) return;

        // Handle TAB
        if (event.type === mya.Search.Event.KEYDOWN && event.which === mya.Search.Key.TAB) {

            let firstFocusable = document.getElementById(mya.Search.Focusable[0]);
            let lastFocusable = document.getElementById(mya.Search.Focusable[mya.Search.Focusable.length - 1]);

            if (event.shiftKey) { // SHIFT+TAB
                if (document.activeElement === firstFocusable) {
                    event.preventDefault();
                    lastFocusable.focus();
                }
            }
            else { // TAB
                if (document.activeElement === lastFocusable) {
                    event.preventDefault();
                    firstFocusable.focus();
                }
            }
        }

        // Handle ESC or CLICK
        if (
            (event.type === mya.Search.Event.KEYUP && event.which === mya.Search.Key.ESC) ||
            (event.type === mya.Search.Event.CLICK && !(event.target).closest('#'+mya.Search.Selector.INPUT))
        ) {
            mya.Search.getInstance().hide();
            return;
        }

    },


    registerEventListeners : function() {
        let overlay = document.getElementById(mya.Search.Selector.OVERLAY);

        overlay && overlay.addEventListener(mya.Search.Event.KEYDOWN, mya.Search.handleEvent);
        overlay && overlay.addEventListener(mya.Search.Event.KEYUP, mya.Search.handleEvent);
        overlay && overlay.addEventListener(mya.Search.Event.CLICK, mya.Search.handleEvent);

    },

    unregisterEventListeners : function() {
        let overlay = document.getElementById(mya.Search.Selector.OVERLAY);

        overlay && overlay.removeEventListener(mya.Search.Event.KEYDOWN, mya.Search.handleEvent);
        overlay && overlay.removeEventListener(mya.Search.Event.KEYUP, mya.Search.handleEvent);
        overlay && overlay.removeEventListener(mya.Search.Event.CLICK, mya.Search.handleEvent);
    },


    init : function() {

        mya.DEBUG && console.debug("mya.Search.init");

        let search = mya.Search.getInstance();
        let searchtoggle = search._getToggle();

        searchtoggle && searchtoggle.addEventListener(mya.Search.Event.CLICK, function handleSearchToggle(event) {
            event.preventDefault();
            search.toggle();
        });

        // When on search-results page, set the same value of the results-page search-input-field into the menubar/overlay search-input-field
        if (mya.init.Search_setInputValue) {
            let inputOnResults = document.getElementById(mya.Search.Selector.INPUT_ON_RESULTS);
            if (inputOnResults) {
                search._getInput().value = inputOnResults.value;
            }
        }

    }

};
