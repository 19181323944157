"use strict";

var mya = mya || {};

mya.Analytics = {

    Event : {

        FORM_ERROR_VALIDATION : {
            'event': 'form_error',
            'form_error_type': 'validation'
        },

        FORM_ERROR_CAPTCHA : {
            'event': 'form_error',
            'form_error_type': 'captcha'
        },

        FORM_START : {
            'event': 'form_start'
        },

        FORM_SUBMIT : {
            'event': 'form_submit'
        }

    },

    // Push event onto the GA4 dataLayer
    pushEvent : function(event) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(event);
    }

};
