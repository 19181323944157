"use strict";

// Initialize mya object / namespace
var mya = mya || {};

// Set this variable to true to print out debug messages in the console
mya.DEBUG = false;

// Vanilla JavaScript equivalent of $(document).ready()
mya.ready = function(callback) {
    document.readyState !== 'loading' ? callback() : document.addEventListener('DOMContentLoaded', callback);
};


/*
Utility function to load a script dynamically.
  - `url`: the script to load.
  - `async`: whether the script should load asynchronously or not; defaults to true; set to false when you need to load multiple scripts in order.
  - `callback`: a function that is called when the script is finished loading.
*/
mya.loadScript = function(url, async, callback) {

    async = async || true;

    callback = callback || function() {};

    let script = document.createElement("script")
    script.async = async;
    script.src = url;
    script.onerror = function() {
        console.error('Failed to load script ' + src);
    };

    if (script.readyState){  // IE
        script.onreadystatechange = function() {
            if (script.readyState == "loaded" || script.readyState == "complete") {
                script.onreadystatechange = null;
                mya.DEBUG && console.debug("Script loaded: " + url);
                callback();
            }
        };
    }
    else {  // Non-IE
        script.onload = function() {
            mya.DEBUG && console.debug("Script loaded: " + url);
            callback();
        };
    }

    document.head.appendChild(script);
};

// Start!
mya.ready(function() {
    mya.init.Catmenu && mya.Catmenu.init();
    mya.init.Contact && mya.Contact.init();
    mya.init.Cookiebar && mya.Cookiebar.init();
    mya.init.Dropdown && mya.Dropdown.init();
    mya.init.Email && mya.Email.init();
    mya.init.NavMenu && mya.NavMenu.init();
    mya.init.Recaptcha && mya.Recaptcha.init();
    mya.init.Search && mya.Search.init();
    mya.init.Testimonial && mya.Testimonial.init();
});
