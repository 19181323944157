"use strict";

var mya = mya || {};

mya.Email = {

    Selector : {
        HREF : 'data-emailhref', // elements with this attribute will be set their href and aria-label with the email address
        TEXT : 'data-emailtext'  // elements with this attribute will be set their html with the email address
    },


    show : function() {

        let emailhref = document.querySelectorAll('['+mya.Email.Selector.HREF+']');
        let emailtext = document.querySelectorAll('['+mya.Email.Selector.TEXT+']');

        if (emailhref || emailtext) {

            let email = mya.Email.decode();

            for (let i = 0; i < emailhref.length; i++) {
                emailhref[i].setAttribute('href', 'mailto:' + email);
                emailhref[i].setAttribute('aria-label', 'Email: ' + email);
            }
            for (let i = 0; i < emailtext.length; i++) {
                emailtext[i].innerHTML = email;
            }
        }
    },


    decode : function() {
    	let encoded = "lyezypwwlapozyp@jlszz.te"; // ROT11
        return encoded.replace(/[a-zA-Z]/g, function(c){
        	// add 15 to decode ROT11
        	return String.fromCharCode((c <= "Z" ? 90 : 122) >= (c = c.charCodeAt(0) + 15) ? c : c - 26);
        })
    },


    init : function() {
        mya.DEBUG && console.debug("mya.Email.init");
        mya.Email.show();
    }

};
