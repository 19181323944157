"use strict";

var mya = mya || {};

mya.Dropdown = {

    Selector : {
        DROPDOWN : 'myaNavbarDropdown',
        TOGGLE : 'myaNavbarDropdownToggle',
        MENU : 'myaNavbarDropdownMenu'
    },


    ClassName : {
        SHOW : 'mya-show'
    },


    Event : {
        CLICK : 'click',
        KEYUP : 'keyup'
    },


    Key : {
        ESC : 27,
        TAB : 9
    },


    Delay : 200,


    // A singleton reference to an instance of Dropdown class.
    // This is used to get a reference to this same instance when outside of the class and without the need to pass parameters.
    _dropdown : null,

    getInstance : function() {
        if (mya.Dropdown._dropdown == null) {
            mya.Dropdown._dropdown = new mya.Dropdown.Dropdown();
        }
        return mya.Dropdown._dropdown;
    },


    Dropdown : class {

        constructor() {
            this._toggle = this._getToggle(); // this is the toggle element
            this._menu = this._getMenu(); // this is the target element
        }

        _getToggle() {
            if (!this._toggle) {
                let toggle = document.getElementById(mya.Dropdown.Selector.TOGGLE);
                if (toggle) {
                    this._toggle = toggle;
                }
                else console.error("dropdowntoggle '"+mya.Dropdown.Selector.TOGGLE+"' not found");
            }
            return this._toggle;
        }

        _getMenu() {
            if (!this._menu) {
                let menu = document.getElementById(mya.Dropdown.Selector.MENU);
                if (menu) {
                    this._menu = menu;
                }
                else console.error("dropdown '"+mya.Dropdown.Selector.MENU+"' not found");

            }
            return this._menu;
        }

        toggle() {
            if ( this._menu.classList.contains(mya.Dropdown.ClassName.SHOW) ) {
                this.hide();
            }
            else {
                this.show();
            }
        }

        show() {
            this._toggle.setAttribute('aria-expanded', true);
            this._menu.classList.add(mya.Dropdown.ClassName.SHOW);

            // (Experimental) Focusing on first item when dropdown is shown. See:
            // https://www.w3.org/TR/wai-aria-practices/examples/menu-button/menu-button-links.html
            // https://www.w3.org/TR/wai-aria-practices/examples/menubar/menubar-1/menubar-1.html
            //
            setTimeout(() => {
                this._menu.querySelector('a').focus();
            }, mya.Dropdown.Delay);

            mya.Dropdown.registerClearingEventListeners();
        }

        hide() {
            this._toggle.setAttribute('aria-expanded', false);
            this._menu.classList.remove(mya.Dropdown.ClassName.SHOW);

            mya.Dropdown.unregisterClearingEventListeners();
        }


    }, // Dropdown class


    handleClearingEvent : function(event) {

        if (!event || event.type === mya.Dropdown.Event.KEYUP && (event.which !== mya.Dropdown.Key.ESC && event.which !== mya.Dropdown.Key.TAB)) {
            return;
        }

        if ((event.which === mya.Dropdown.Key.ESC) ||
            (event.type === mya.Dropdown.Event.CLICK && !(event.target).closest('#'+mya.Dropdown.Selector.DROPDOWN)) ||
            (event.which === mya.Dropdown.Key.TAB && !(event.target).closest('#'+mya.Dropdown.Selector.DROPDOWN))
        ) {
            mya.Dropdown.getInstance().hide();
            return;
        }

    },

    registerClearingEventListeners : function() {
        document.addEventListener(mya.Dropdown.Event.KEYUP, mya.Dropdown.handleClearingEvent);
        document.addEventListener(mya.Dropdown.Event.CLICK, mya.Dropdown.handleClearingEvent);
    },

    unregisterClearingEventListeners : function() {
        document.removeEventListener(mya.Dropdown.Event.KEYUP, mya.Dropdown.handleClearingEvent);
        document.removeEventListener(mya.Dropdown.Event.CLICK, mya.Dropdown.handleClearingEvent);
    },


    init : function() {

        mya.DEBUG && console.debug("mya.Dropdown.init");

        let dropdown = mya.Dropdown.getInstance();
        let dropdowntoggle = dropdown._getToggle();

        dropdowntoggle && dropdowntoggle.addEventListener(mya.Dropdown.Event.CLICK, function handleDropdownToggle(event) {
            event.preventDefault();
            event.stopPropagation(); // Stop event propagation because this event must be confined to dropdowntoggle;
                                     // it must not bubble up to document since document has other listeners attached for handling ESC, TAB and click
            dropdown.toggle();
        });
    }

};
