"use strict";

var mya = mya || {};

// This script REQUIRES the `grecaptcha` object which comes with the recaptcha api javascript loaded from Google

mya.Recaptcha = {

    Selector : {
        SCRIPT : "recaptcha-script",
        DIV : "recaptcha-div"
    },


    Event : {
        FOCUS : 'focus'
    },


    Data : {
        SRC : "//www.google.com/recaptcha/api.js?hl=it",
        SITEKEY : "6LcYqf4aAAAAABbABw5uFqVSMH68p_zmwmjTlbeg", // this gets replaced with actual sitekey at build time
        CALLBACK_SUBMIT : "onSubmitCallback",
        CALLBACK_EXPIRED : "onExpiredCallback",
        CALLBACK_ERROR : "onErrorCallback"
    },


    init : function() {

        mya.DEBUG && console.debug("mya.Recaptcha.init");

        // Register listener to catch user interacting with contact form: recaptcha is loaded only when user interacts with the form.
        // Even though Google recommends to load recaptcha as soon as possible, this lazy loading strategy improves page performances.
        document.getElementById(mya.Contact.Selector.NAME).addEventListener(mya.Recaptcha.Event.FOCUS, mya.Recaptcha._handleFocusEvent);
        document.getElementById(mya.Contact.Selector.PHONE).addEventListener(mya.Recaptcha.Event.FOCUS, mya.Recaptcha._handleFocusEvent);
        document.getElementById(mya.Contact.Selector.EMAIL).addEventListener(mya.Recaptcha.Event.FOCUS, mya.Recaptcha._handleFocusEvent);
        document.getElementById(mya.Contact.Selector.MESSAGE).addEventListener(mya.Recaptcha.Event.FOCUS, mya.Recaptcha._handleFocusEvent);
        document.getElementById(mya.Contact.Selector.PRIVACY).addEventListener(mya.Recaptcha.Event.FOCUS, mya.Recaptcha._handleFocusEvent);

        // Create the hidden div to hold recaptcha variables that are submitted with the form
        mya.Recaptcha._createDiv();
    },


    _createDiv : function() {

        var div = document.createElement('div');
        div.id = mya.Recaptcha.Selector.DIV;

        div.className = "g-recaptcha";
        div.setAttribute('data-size', "invisible");
        div.setAttribute('data-sitekey', mya.Recaptcha.Data.SITEKEY);
        div.setAttribute('data-callback', mya.Recaptcha.Data.CALLBACK_SUBMIT);
        
        // Not used as of now.
        // div.setAttribute('error-callback', mya.Recaptcha.Data.CALLBACK_ERROR);
        // div.setAttribute('expired-callback', mya.Recaptcha.Data.CALLBACK_EXPIRED);

        document.getElementById(mya.Contact.Selector.FORM).appendChild(div);
    },


    _loadRecaptcha : function() {

        // If the script element already exists, then remove it.
        // This allows re-loading the recaptcha script from Google (by clicking the submit button), when previous loading failed.
        document.getElementById(mya.Recaptcha.Selector.SCRIPT) && document.getElementById(mya.Recaptcha.Selector.SCRIPT).remove();

        // Create the script element for Google reCaptcha
        var script = document.createElement('script');
        script.id = mya.Recaptcha.Selector.SCRIPT;

        script.src = mya.Recaptcha.Data.SRC;

        script.onerror = function() {
            console.error("[ERROR] [recaptcha._loadRecaptcha] Failed loading script from " + script.src);
            alert("Non è stato possibile caricare reCAPTCHA. Verifica la connessione Internet e riprova.");
        };

        document.head.appendChild(script);
    },


    _handleFocusEvent : function() {

        // Remove event listeners
        // The only reason I'm not (yet) using the 'once' option of addEventListener, is to maximize compatibility with older browsers (does not work with IE11)
        document.getElementById(mya.Contact.Selector.NAME).removeEventListener(mya.Recaptcha.Event.FOCUS, mya.Recaptcha._handleFocusEvent);
        document.getElementById(mya.Contact.Selector.PHONE).removeEventListener(mya.Recaptcha.Event.FOCUS, mya.Recaptcha._handleFocusEvent);
        document.getElementById(mya.Contact.Selector.EMAIL).removeEventListener(mya.Recaptcha.Event.FOCUS, mya.Recaptcha._handleFocusEvent);
        document.getElementById(mya.Contact.Selector.MESSAGE).removeEventListener(mya.Recaptcha.Event.FOCUS, mya.Recaptcha._handleFocusEvent);
        document.getElementById(mya.Contact.Selector.PRIVACY).removeEventListener(mya.Recaptcha.Event.FOCUS, mya.Recaptcha._handleFocusEvent);

        // (Lazy) Load recaptcha now!
        mya.Recaptcha._loadRecaptcha();

        // Push analytics event
        // Note: in order to refactor this out into the contact.js module, I'll need to attach another dedicated Listener from within contact.js
        mya.Analytics.pushEvent(mya.Analytics.Event.FORM_START);

    },


    // This 'wait' implementation is based on code snippet provided by Google
    // See: https://developers.google.com/recaptcha/docs/loading
    //
    execute : function() {

        // This function requires grecaptcha, but it might not have been loaded yet

        // If it is (still) undefined, then load it
        // NOTICE: This may overlap with another already-running _loadRecaptcha(), but the browser seems to handle this well
        if (typeof grecaptcha === 'undefined' || typeof grecaptcha.execute === 'undefined') {
            mya.Recaptcha._loadRecaptcha();
        }

        // Call execute as soon as grecaptcha is ready
        grecaptcha.ready(function() {
            //
            // See notes in issue #50
            //
            grecaptcha.execute().then(function(token) {
                console.log("[recaptcha.execute] OK");
            }).catch(function(error) {
                console.error("[ERROR] [recaptcha.execute] grecaptcha.execute returned error: " + error);
                grecaptcha.reset();
            });            

        });

    },


    submit : function(token) {

        if (token === null || token == undefined || token.length == 0) {
            console.error("[ERROR] [recaptcha.submit] token is null");

            // Push analytics event
            mya.Analytics.pushEvent(mya.Analytics.Event.FORM_ERROR_CAPTCHA);

            grecaptcha.reset();
            alert("Si è verificato un errore con reCAPTCHA, per favore riprova.");
        }
        else {
            // Return back to contact.js for actual form submission
            mya.Contact.submitForm()
        }

    },

};


function onSubmitCallback(token) {
    mya.Recaptcha.submit(token);
};


// Not used as of now
function onErrorCallback(token) {
    console.log("mya.Recaptcha.onErrorCallback");
};

// Not used as of now
function onExpiredCallback(token) {
    console.log("mya.Recaptcha.onExpiredCallback");
};


// `grecaptcha.ready` logic, as provided by Google:
//
// This logic overwrites the default behavior of `grecaptcha.ready()` to ensure that it can be safely called at any time.
// When `grecaptcha.ready()` is called before reCAPTCHA is loaded, the callback function that is passed
// by `grecaptcha.ready()` is enqueued for execution after reCAPTCHA is loaded.
//
// See: https://developers.google.com/recaptcha/docs/loading

var grecaptcha = grecaptcha || {};

grecaptcha.ready = function(callback) {

    if (typeof grecaptcha === 'undefined' || typeof grecaptcha.execute === 'undefined') {

        // window.__grecaptcha_cfg is a global variable that stores reCAPTCHA's configuration.
        // By default, any functions listed in its 'fns' property are automatically executed when reCAPTCHA loads.
        const cfg = '___grecaptcha_cfg';
        window[cfg] = window[cfg] || {};
        (window[cfg]['fns'] = window[cfg]['fns'] || []).push(callback);
    }
    else {
        // grecaptcha is defined, now calling the callback
        callback();
    }
};
// End of `grecaptcha.ready` logic.
