"use strict";

var mya = mya || {};

mya.Catmenu = {

    Selector : {
        TOGGLE : 'myaCatbarToggle',
        MENU : 'myaCatbar'
    },


    Event : {
        CLICK : 'click'
    },


    ClassName : {
        SHOW : 'mya-show'
    },


    Delay : 200,


    Catmenu : class {

        constructor() {
            this._toggle = this._getToggle(); // this is the toggle element
            this._menu = this._getMenu(); // this is the target element
        }

        _getToggle() {
            if (!this._toggle) {
                let toggle = document.getElementById(mya.Catmenu.Selector.TOGGLE);
                if (toggle) {
                    this._toggle = toggle;
                }
                else console.error("catmenutoggle '"+mya.Catmenu.Selector.TOGGLE+"' not found");
            }
            return this._toggle;
        }

        _getMenu() {
            if (!this._menu) {
                let menu = document.getElementById(mya.Catmenu.Selector.MENU);
                if (menu) {
                    this._menu = menu;
                }
                else console.error("catmenu '"+mya.Catmenu.Selector.MENU+"' not found");
            }
            return this._menu;
        }

        toggle() {
            if ( this._menu.classList.contains(mya.Catmenu.ClassName.SHOW) ) {
                this.hide();
            }
            else {
                this.show();
            }
        }

        show() {
            this._toggle.setAttribute('aria-expanded', true);
            this._menu.classList.add(mya.Catmenu.ClassName.SHOW);

            // (Experimental) Focusing on first item when dropdown is shown. (See: Dropdown.js)
            setTimeout(() => {
                this._menu.querySelector('a').focus();
            }, mya.Catmenu.Delay);
        }

        hide() {
            this._toggle.setAttribute('aria-expanded', false);
            this._menu.classList.remove(mya.Catmenu.ClassName.SHOW);
        }

    },


    init : function() {

        mya.DEBUG && console.debug("mya.Catmenu.init");

        let catmenu = new mya.Catmenu.Catmenu();

        let catmenutoggle = document.getElementById(mya.Catmenu.Selector.TOGGLE);

        catmenutoggle && catmenutoggle.addEventListener(mya.Catmenu.Event.CLICK, function handleCatmenuToggle(event){
            event.preventDefault();
            catmenu.toggle();
        });
    }

};
