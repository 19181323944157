"use strict";

var mya = mya || {};

// This script REQUIRES the `flickity` object which is dynamically loaded

mya.Testimonial = {

    Selector : {
        REVIEWS : ".mya-testimonial-reviews",
        REVIEW_CELL : ".mya-testimonial__review"
    },


    create : function() {
        mya.DEBUG && console.debug("mya.Testimonial.create");

        new Flickity(mya.Testimonial.Selector.REVIEWS, {
            cellSelector: mya.Testimonial.Selector.REVIEW_CELL,
            cellAlign: 'center',
            percentPosition: true,
            wrapAround: true
        });
    },


    init : function() {
        mya.DEBUG && console.debug("mya.Testimonial.init");
        mya.loadScript('/js/vendor/flickity.pkgd.min.js', false, mya.Testimonial.create);
    }

};
