"use strict";

var mya = mya || {};

mya.Contact = {

    Selector : {
        FORM : "myaFormContact",
        BUTTON : "myaFormContactButton",
        NAME : "myaContactName",
        PHONE : "myaContactPhone",
        EMAIL : "myaContactEmail",
        MESSAGE : "myaContactMessage",
        PRIVACY : "myaContactPrivacy",
        COUNTER : "myaContactMessageCounter",
        NAME_FEEDBACK_INVALID : "myaContactNameFeedbackInvalid",
        PHONE_FEEDBACK_INVALID : "myaContactPhoneFeedbackInvalid",
        EMAIL_FEEDBACK_INVALID : "myaContactEmailFeedbackInvalid",
        MESSAGE_FEEDBACK_INVALID : "myaContactMessageFeedbackInvalid",
        PRIVACY_FEEDBACK_INVALID : "myaContactMessageFeedbackInvalid",
        CONTACT_ANCHOR : "contatta-lo-studio",
        CONTACT_ASK_QUOTE : "myaButtonAskQuote"
    },


    Event : {
        BLUR : 'blur',
        CHANGE : 'change',
        CLICK : 'click',
        KEYUP : 'keyup',
        SUBMIT: 'submit'
    },


    Regex : {
        NAME : /^[a-zA-Z\u00c0-\u024f ,.'\-]+$/,
        PHONE : /^(?=.*\d)[0-9 +\(\).\-]*$/,
        EMAIL : /^[a-zA-Z0-9_+\-]+(?:\.[a-zA-Z0-9_+\-]+)*@(?:[a-zA-Z0-9\-]+\.)+[a-zA-Z]{2,63}$/
    },


    Feedback : {
        NAME_MISSING : "Inserisci il tuo nome",
        NAME_MISMATCH : "Il nome può contenere solo lettere e i simboli , . - '",
        NAME_INVALID : "Nome non valido",
        PHONE_MISSING : "Inserisci il tuo telefono",
        PHONE_MISMATCH : "Il telefono può contenere solo numeri e i simboli + - . ( )",
        PHONE_INVALID : "Telefono non valido",
        EMAIL_MISSING : "Inserisci la tua email",
        EMAIL_MISMATCH : "L'email deve rispettare il formato email@example.com e può contenere solo lettere, numeri e i simboli @ . _ + -",
        EMAIL_INVALID : "Email non valida",
        MESSAGE_MISSING : "Scrivi un messaggio",
        MESSAGE_TOOLONG : "Il messaggio può contenere fino a 5000 caratteri",
        PRIVACY_MISSING : "Devi accettare l'informativa sulla privacy"
    },


    ClassName : {
        VALID : "is-valid",
        INVALID : "is-invalid",
        SUBMITTED : "was-submitted",
        COUNTER_WARNING : "mya-contact-form__counter_warning",
        COUNTER_EXCEEDED : "mya-contact-form__counter_exceeded"
    },


    Limit : {
        MESSAGE_ALLOWED : 5000,
        MESSAGE_WARNING : 4500
    },


    init : function() {

        mya.DEBUG && console.debug("mya.Contact.init");

        // Enable the submit button (it is disabled by default for no-js scenarios)
        document.getElementById(mya.Contact.Selector.BUTTON).disabled = false;

        var contact = new mya.Contact.Contact();

        document.getElementById(mya.Contact.Selector.NAME).addEventListener(mya.Contact.Event.BLUR, function handleValidateName(event) {
            contact.validateName(this);
        });

        document.getElementById(mya.Contact.Selector.PHONE).addEventListener(mya.Contact.Event.BLUR, function handleValidatePhone(event) {
            contact.validatePhone(this);
        });

        document.getElementById(mya.Contact.Selector.EMAIL).addEventListener(mya.Contact.Event.BLUR, function handleValidateEmail(event) {
            contact.validateEmail(this);
        });

        document.getElementById(mya.Contact.Selector.MESSAGE).addEventListener(mya.Contact.Event.BLUR, function handleValidateMessage(event) {
            contact.validateMessage(this);
        });

        document.getElementById(mya.Contact.Selector.PRIVACY).addEventListener(mya.Contact.Event.CHANGE, function handleValidatePrivacy(event) {
            contact.validatePrivacy(this);
        });

        // This listener is attached to the submit event of the form,
        // this seems to me more coherent and robust than attaching it onto the click event of the submit button.
        // Notice that, differently than ONCLICK, this will trigger browser validation.
        document.getElementById(mya.Contact.Selector.FORM).addEventListener(mya.Contact.Event.SUBMIT, function handleFormSubmit(event) {
            event.preventDefault();
            contact.validateSubmit();
        });

        // Attach events for counting characters
        document.getElementById(mya.Contact.Selector.MESSAGE).addEventListener(mya.Contact.Event.KEYUP, function handleCountCharacters(event) {
            contact.countCharacters(this);
        });
        document.getElementById(mya.Contact.Selector.MESSAGE).addEventListener(mya.Contact.Event.BLUR, function handleCountCharacters(event) {
            contact.countCharacters(this);
        });

        // Attach events to Ask-for button (if present) so to jump to contact form anchor:
        let buttonAskQuote = document.getElementById(mya.Contact.Selector.CONTACT_ASK_QUOTE);
        if (buttonAskQuote) {
            buttonAskQuote.addEventListener(mya.Contact.Event.CLICK, mya.Contact.jumpToContactForm);
        }

    },


    jumpToContactForm : function(event) {
        event.preventDefault();
        document.getElementById(mya.Contact.Selector.CONTACT_ANCHOR).scrollIntoView();
        document.getElementById(mya.Contact.Selector.NAME).focus();
    },


    submitForm() {

        document.getElementById(mya.Contact.Selector.BUTTON).textContent = "Invio in corso...";

        // Push analytics event
        mya.Analytics.pushEvent(mya.Analytics.Event.FORM_SUBMIT);

        // Finally submit the form!
        document.getElementById(mya.Contact.Selector.FORM).submit();
    },


    Contact : class {

        constructor() {

            this._counter = document.getElementById(mya.Contact.Selector.COUNTER);
            if (this._counter) {
                this._counter.textContent = mya.Contact.Limit.MESSAGE_ALLOWED;
            }

        }

        validateName(field) {

            field.value = field.value?.trim();
            let isValid = (mya.Contact.Regex.NAME).test(field.value);

            if (isValid) {
                field.setCustomValidity("");
                field.classList.add(mya.Contact.ClassName.VALID);
                field.classList.remove(mya.Contact.ClassName.INVALID);
                field.setAttribute('aria-invalid', false);
                return true;
            }
            else {
                if (field.validity.valueMissing) {
                    field.setCustomValidity(mya.Contact.Feedback.NAME_MISSING);
                    document.getElementById(mya.Contact.Selector.NAME_FEEDBACK_INVALID).textContent = mya.Contact.Feedback.NAME_MISSING;
                }
                else if (field.validity.patternMismatch) {
                    field.setCustomValidity(mya.Contact.Feedback.NAME_MISMATCH);
                    document.getElementById(mya.Contact.Selector.NAME_FEEDBACK_INVALID).textContent = mya.Contact.Feedback.NAME_MISMATCH;
                }
                else {
                    field.setCustomValidity(mya.Contact.Feedback.NAME_INVALID);
                    document.getElementById(mya.Contact.Selector.NAME_FEEDBACK_INVALID).textContent = mya.Contact.Feedback.NAME_INVALID;
                }

                field.classList.add(mya.Contact.ClassName.INVALID);
                field.classList.remove(mya.Contact.ClassName.VALID);
                field.setAttribute('aria-invalid', true);
                return false;
            }
        }

        validatePhone(field) {

            field.value = field.value?.trim();

            if (!field.required && field.value === "") {
                field.setCustomValidity("");
                field.classList.remove(mya.Contact.ClassName.VALID);
                field.classList.remove(mya.Contact.ClassName.INVALID);
                field.removeAttribute('aria-invalid')
                return true;
            }

            let isValid = (mya.Contact.Regex.PHONE).test(field.value);

            if (isValid) {
                field.setCustomValidity("");
                field.classList.add(mya.Contact.ClassName.VALID);
                field.classList.remove(mya.Contact.ClassName.INVALID);
                field.setAttribute('aria-invalid', false);
                return true;
            }
            else {
                if (field.validity.valueMissing) {
                    field.setCustomValidity(mya.Contact.Feedback.PHONE_MISSING);
                    document.getElementById(mya.Contact.Selector.PHONE_FEEDBACK_INVALID).textContent = mya.Contact.Feedback.PHONE_MISSING;
                }
                else if (field.validity.patternMismatch) {
                    field.setCustomValidity(mya.Contact.Feedback.PHONE_MISMATCH);
                    document.getElementById(mya.Contact.Selector.PHONE_FEEDBACK_INVALID).textContent = mya.Contact.Feedback.PHONE_MISMATCH;
                }
                else {
                    field.setCustomValidity(mya.Contact.Feedback.PHONE_INVALID);
                    document.getElementById(mya.Contact.Selector.PHONE_FEEDBACK_INVALID).textContent = mya.Contact.Feedback.PHONE_INVALID;
                }

                field.classList.add(mya.Contact.ClassName.INVALID);
                field.classList.remove(mya.Contact.ClassName.VALID);
                field.setAttribute('aria-invalid', true);
                return false;
            }
        }

        validateEmail(field) {

            field.value = field.value?.trim();
            let isValid = (mya.Contact.Regex.EMAIL).test(field.value);

            if (isValid) {
                field.setCustomValidity("");
                field.classList.add(mya.Contact.ClassName.VALID);
                field.classList.remove(mya.Contact.ClassName.INVALID);
                field.setAttribute('aria-invalid', false);
                return true;
            }
            else {
                if (field.validity.valueMissing) {
                    field.setCustomValidity(mya.Contact.Feedback.EMAIL_MISSING);
                    document.getElementById(mya.Contact.Selector.EMAIL_FEEDBACK_INVALID).textContent = mya.Contact.Feedback.EMAIL_MISSING;
                }
                else if (field.validity.patternMismatch) {
                    field.setCustomValidity(mya.Contact.Feedback.EMAIL_MISMATCH);
                    document.getElementById(mya.Contact.Selector.EMAIL_FEEDBACK_INVALID).textContent = mya.Contact.Feedback.EMAIL_MISMATCH;
                }
                else {
                    field.setCustomValidity(mya.Contact.Feedback.EMAIL_INVALID);
                    document.getElementById(mya.Contact.Selector.EMAIL_FEEDBACK_INVALID).textContent = mya.Contact.Feedback.EMAIL_INVALID;
                }

                field.classList.add(mya.Contact.ClassName.INVALID);
                field.classList.remove(mya.Contact.ClassName.VALID);
                field.setAttribute('aria-invalid', true);
                return false;
            }
        }

        validateMessage(field) {

            field.value = field.value?.trim();

            let count = field.value?.length;
            if (count == 0) {
                field.setCustomValidity(mya.Contact.Feedback.MESSAGE_MISSING);
                document.getElementById(mya.Contact.Selector.MESSAGE_FEEDBACK_INVALID).textContent = mya.Contact.Feedback.MESSAGE_MISSING;
                field.classList.add(mya.Contact.ClassName.INVALID);
                field.classList.remove(mya.Contact.ClassName.VALID);
                field.setAttribute('aria-invalid', true);
                return false;
            }
            else if (count > mya.Contact.Limit.MESSAGE_ALLOWED) {
                field.setCustomValidity(mya.Contact.Feedback.MESSAGE_TOOLONG);
                document.getElementById(mya.Contact.Selector.MESSAGE_FEEDBACK_INVALID).textContent = mya.Contact.Feedback.MESSAGE_TOOLONG;
                field.classList.add(mya.Contact.ClassName.INVALID);
                field.classList.remove(mya.Contact.ClassName.VALID);
                field.setAttribute('aria-invalid', true);
                return false;
            }
            else {
                field.setCustomValidity("");
                field.classList.add(mya.Contact.ClassName.VALID);
                field.classList.remove(mya.Contact.ClassName.INVALID);
                field.setAttribute('aria-invalid', false);
                return true;
            }
        }

        validatePrivacy(field) {

            if (field.checked) {
                field.setCustomValidity("");
                field.classList.add(mya.Contact.ClassName.VALID);
                field.classList.remove(mya.Contact.ClassName.INVALID);
                field.setAttribute('aria-invalid', false);
                return true;
            }
            else {
                field.setCustomValidity(mya.Contact.Feedback.PRIVACY_MISSING);
                field.classList.add(mya.Contact.ClassName.INVALID);
                field.classList.remove(mya.Contact.ClassName.VALID);
                field.setAttribute('aria-invalid', true);
                return false;
            }
        }

        countCharacters(field) {

            if (field == null) return;

            let count = field.value?.length;
            let available = mya.Contact.Limit.MESSAGE_ALLOWED - count;

            this._counter.textContent = available;

            if (available >= 0) {

                this._counter.classList.remove(mya.Contact.ClassName.COUNTER_EXCEEDED);

                if (available <= (mya.Contact.Limit.MESSAGE_ALLOWED - mya.Contact.Limit.MESSAGE_WARNING)) {
                    this._counter.classList.add(mya.Contact.ClassName.COUNTER_WARNING);
                }
                else {
                    this._counter.classList.remove(mya.Contact.ClassName.COUNTER_WARNING);
                }

            }
            else {
                this._counter.classList.add(mya.Contact.ClassName.COUNTER_EXCEEDED);
                this._counter.classList.remove(mya.Contact.ClassName.COUNTER_WARNING);
            }
        }

        validateSubmit() {

            let form = document.getElementById(mya.Contact.Selector.FORM);

            // Mark the form as submitted (validation state may be true or false):
            // this triggers the was-submitted class on the form in order to display the feedback messages.
            form.classList.add(mya.Contact.ClassName.SUBMITTED);

            // Validate form:

            // Let every field always be validated so that appropriate error messages are set in case of error
            let isValidName = this.validateName(document.getElementById(mya.Contact.Selector.NAME));
            let isValidPhone = this.validatePhone(document.getElementById(mya.Contact.Selector.PHONE));
            let isValidEmail = this.validateEmail(document.getElementById(mya.Contact.Selector.EMAIL));
            let isValidMessage = this.validateMessage(document.getElementById(mya.Contact.Selector.MESSAGE));
            let isValidPrivacy = this.validatePrivacy(document.getElementById(mya.Contact.Selector.PRIVACY));

            let isValid = isValidName && isValidPhone && isValidEmail && isValidMessage && isValidPrivacy;

            // Check validation results:
            if (isValid) {
                // Remove any leftover form-invalid class - NOT ANYMORE
                // NOTICE: This caused the view to scroll up when the user click submit and the form top-level error message was displayed - due to this message being hidden 
                // Leaving the 'is-invalid' class there, does not impact execution of recaptcha and the subsequent submission
                // form.classList.remove(mya.Contact.ClassName.INVALID);

                // Run Captcha, this will also take care of submitting the form 
                mya.Recaptcha.execute();
            }
            else {
                // Mark the whole form as invalid, so that the top error message will be displayed
                form.classList.add(mya.Contact.ClassName.INVALID);
                // Jump to the first invalid field
                document.getElementById(mya.Contact.Selector.CONTACT_ANCHOR).scrollIntoView();
                form.querySelector(':invalid').focus();

                // Push analytics event
                mya.Analytics.pushEvent(mya.Analytics.Event.FORM_ERROR_VALIDATION);
            }

        }

    } // Contact class

};
